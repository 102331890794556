export const regions = {
  "Polynesia": ["Samoa", "Tonga", "Tuvalu"],
  "Melanesia": ["Papua New Guinea", "Vanuatu", "Solomon Islands", "Fiji"],
  "Micronesia": ["Palau", "Nauru", "Marshall Islands", "Federated States of Micronesia", "Kiribati"]
};

const green = "rgba(42, 149, 130, 0.5)"

export default {
  Australia: {
    name: "Australia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-27, 133],
    styles: [green, green],
  },
  "Federated-States-of-Micronesia": {
    name: "Federated States of Micronesia",
    id: "Federated-States-of-Micronesia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [6.91666666, 158.25],
    styles: [green, green],
  },
  Fiji: {
    name: "Fiji",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-18, 175],
    styles: [green, green],
  },
  Kiribati: {
    name: "Kiribati",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [1.41666666, 173],
    styles: [green, green],
  },
  "Marshall-Islands": {
    name: "Marshall Islands",
    id: "Marshall-Islands",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [9, 168],
    styles: [green, green],
  },
  Nauru: {
    name: "Nauru",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-0.53333333, 166.91666666],
    styles: [green, green],
  },
  "New-Zealand": {
    name: "New Zealand",
    id: "New-Zealand",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-41, 174],
    styles: [green, green],
  },
  Palau: {
    name: "Palau",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [7.5, 134.5],
    styles: [green, green],
  },
  "Papua-New-Guinea": {
    name: "Papua New Guinea",
    id: "Papua-New-Guinea",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-6, 147],
    styles: [green, green],
  },
  Samoa: {
    name: "Samoa",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-13.58333333, -172.33333333],
    styles: [green, green],
  },
  "Solomon-Islands": {
    name: "Solomon Islands",
    id: "Solomon-Islands",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-8, 159],
    styles: [green, green],
  },
  Tonga: {
    name: "Tonga",
    capital: "N/A",
    class: ["quiz-country"],
    styles: [green, green],
    latlng: [-20, -175],
  },
  Tuvalu: {
    name: "Tuvalu",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-8, 178],
    styles: [green, green],
  },
  Vanuatu: {
    name: "Vanuatu",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-16, 167],
    styles: [green, green],
  },
};
