import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GameContext } from "./gameContext";

const Header = (match) => {
  const { game, header } = useContext(GameContext);
  return (
    <>
      {header && (

        <div className="header-container">
           <input type="checkbox" id="hamburger-input" className="burger-shower" />
           <label id="hamburger-menu" htmlFor="hamburger-input">
              <nav id="sidebar-menu">
                <ul>
                  <li><h6>Back</h6></li>
                  <li><Link to="/quiz/africa/countries">
                    Africa
                  </Link></li>
                  <li><Link to="/quiz/asia/countries">
                    Asia
                  </Link></li>
                  <li><Link to="/quiz/europe/countries">
                    Europe
                  </Link></li>
                  <li><Link to="/quiz/north-america/countries">
                    North America
                  </Link></li>
                  <li><Link to="/quiz/oceania/countries">
                    Oceania
                  </Link></li>
                  <li><Link to="/quiz/south-america/countries">
                    South America
                  </Link></li>
                </ul>
              </nav>
            </label>
          <div id="overlay"></div>
          <div className="Header">
            <span>
              <Link to="/" className="header-logo">
                <img src={require("../../assets/images/logo2.svg")} />
              </Link>
            </span>
            <span id="main-nav">
              <Link to="/quiz/africa/countries" className="header-quiz">
                Africa
              </Link>
              <Link to="/quiz/asia/countries" className="header-quiz">
                Asia
              </Link>
              <Link to="/quiz/europe/countries" className="header-quiz">
                Europe
              </Link>
              <Link to="/quiz/north-america/countries" className="header-quiz">
                North America
              </Link>
              <Link to="/quiz/oceania/countries" className="header-quiz">
                Oceania
              </Link>
              <Link to="/quiz/south-america/countries" className="header-quiz">
                South America
              </Link>
              {/* <div>LEARNING</div> */}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
