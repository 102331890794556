export const regions = {
  "North": ["Canada", "USA", "Mexico"],
  "Caribbean": ["Antigua and Barbuda", "Bahamas", "Barbados", "Cuba", "Dominica", "Dominican Republic", "Grenada", 
  "Haiti", "Jamaica", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Trinidad and Tobago"],
  "Central America": ["Belize", "Costa Rica", "El Salvador", "Guatemala", "Honduras", "Nicaragua", "Panama"]
};

export default {
  "Antigua-and-Barbuda": {
    name: "Antigua and Barbuda",
    id: "Antigua-and-Barbuda",
    assist: "box",
    capital: "Saint John's",
    class: ["quiz-country"],
    latlng: [17.05, -61.8],
    styles: ["#4A9B61", "#5FD981"],
  },
  Bahamas: {
    name: "Bahamas",
    capital: "Nassau",
    assist: "box",
    class: ["quiz-country"],
    latlng: [24.25, -76],
    styles: ["#4A9B61", "#5FD981"],
  },
  Barbados: {
    name: "Barbados",
    capital: "Bridgetown",
    assist: "box",
    class: ["quiz-country"],
    latlng: [13.16666666, -59.53333333],
    styles: ["#4A9B61", "#5FD981"],
  },
  Belize: {
    name: "Belize",
    capital: "Belmopan",
    assist: "box",
    class: ["quiz-country"],
    latlng: [17.25, -88.75],
    styles: ["#4A9B61", "#5FD981"],
  },
  Canada: {
    name: "Canada",
    capital: "Ottawa",
    class: ["quiz-country"],
    latlng: [60, -95],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Costa-Rica": {
    name: "Costa Rica",
    id: "Costa-Rica",
    assist: "box",
    capital: "San José",
    class: ["quiz-country"],
    latlng: [10, -84],
    styles: ["#4A9B61", "#5FD981"],
  },
  Cuba: {
    name: "Cuba",
    capital: "Havana",
    assist: "box",
    class: ["quiz-country"],
    latlng: [21.5, -80],
    styles: ["#4A9B61", "#5FD981"],
  },
  Dominica: {
    name: "Dominica",
    capital: "Roseau",
    assist: "box",
    class: ["quiz-country"],
    latlng: [15.41666666, -61.33333333],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Dominican-Republic": {
    name: "Dominican Republic",
    id: "Dominican-Republic",
    assist: "box",
    capital: "Santo Domingo",
    class: ["quiz-country"],
    latlng: [19, -70.66666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  "El-Salvador": {
    name: "El Salvador",
    id: "El-Salvador",
    assist: "box",
    capital: "San Salvador",
    class: ["quiz-country"],
    latlng: [13.83333333, -88.91666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Grenada: {
    name: "Grenada",
    capital: "Saint George's",
    assist: "box",
    class: ["quiz-country"],
    latlng: [12.11666666, -61.66666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Guatemala: {
    name: "Guatemala",
    capital: "Guatemala City",
    class: ["quiz-country"],
    latlng: [15.5, -90.25],
    styles: ["#4A9B61", "#5FD981"],
  },
  Haiti: {
    name: "Haiti",
    capital: "Port-au-Prince",
    assist: "box",
    class: ["quiz-country"],
    latlng: [19, -72.41666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Honduras: {
    name: "Honduras",
    capital: "Tegucigalpa",
    class: ["quiz-country"],
    latlng: [15, -86.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Jamaica: {
    name: "Jamaica",
    capital: "Kingston",
    assist: "box",
    class: ["quiz-country"],
    latlng: [18.25, -77.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Mexico: {
    name: "Mexico",
    capital: "Mexico City",
    class: ["quiz-country"],
    latlng: [23, -102],
    styles: ["#4A9B61", "#5FD981"],
  },
  Nicaragua: {
    name: "Nicaragua",
    capital: "Managua",
    class: ["quiz-country"],
    latlng: [13, -85],
    styles: ["#4A9B61", "#5FD981"],
  },
  Panama: {
    name: "Panama",
    capital: "Panama City",
    class: ["quiz-country"],
    latlng: [9, -80],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Saint-Kitts-and-Nevis": {
    name: "Saint Kitts and Nevis",
    id: "Saint-Kitts-and-Nevis",
    assist: "box",
    capital: "Basseterre",
    class: ["quiz-country"],
    latlng: [17.33333333, -62.75],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Saint-Lucia": {
    name: "Saint Lucia",
    id: "Saint-Lucia",
    assist: "box",
    capital: "Castries",
    class: ["quiz-country"],
    latlng: [13.88333333, -60.96666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Saint-Vincent-and-the-Grenadines": {
    name: "Saint Vincent and the Grenadines",
    id: "Saint-Vincent-and-the-Grenadines",
    assist: "box",
    capital: "Kingstown",
    class: ["quiz-country"],
    latlng: [13.25, -61.2],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Trinidad-and-Tobago": {
    name: "Trinidad and Tobago",
    id: "Trinidad-and-Tobago",
    assist: "box",
    capital: "Port of Spain",
    class: ["quiz-country"],
    latlng: [11, -61],
    styles: ["#4A9B61", "#5FD981"],
  },
  USA: {
    name: "United States of America",
    id: "USA",
    capital: "Washington, D.C.",
    class: ["quiz-country"],
    latlng: [38, -97],
    styles: ["#4A9B61", "#5FD981"],
  },
};
