export const regions = {
  "North": [
    "Georgia",
    "Russia", "Armenia", "Azerbaijan"
  ],
  "Central": ["Afghanistan",
  "Kazakhstan",
  "Kyrgyzstan",
  "Mongolia", 
  "Tajikistan",
  "Turkmenistan",
  "Uzbekistan"],

  "Middle-East": [
    "Bahrain",
    "Iran",
    "Iraq",
    "Israel",
    "Jordan",
    "Kuwait",
    "Lebanon",
    "Oman",
    "Qatar",
    "Saudi Arabia",
    "Syria",
    "Turkey",
    "United Arab Emirates",
    "Yemen"
  ],
  "East": ["China", "Japan", "North Korea", "South Korea", "Taiwan"],
  "South": [
    "Bangladesh",
    "Bhutan",
    "India",
    "Maldives",
    "Nepal",
    "Pakistan",
    "Sri Lanka"
  ],
  "SouthEast": [
    "Brunei",
    "Cambodia",
    "Indonesia",
    "Laos",
    "Malaysia",
    "Myanmar",
    "Philippines",
    "Singapore",
    "Thailand",
    "Timor Leste",
    "Vietnam"
  ]
};

export default {
  Afghanistan: {
    name: "Afghanistan",
    capital: "Kabul",
    class: ["quiz-country"],
    latlng: [33, 65],
    styles: ["#4A9B61", "#5FD981"],
  },
  Armenia: {
    name: "Armenia",
    capital: "Yerevan",
    assist: "box",
    class: ["quiz-country"],
    latlng: [40, 45],
    styles: ["#4A9B61", "#5FD981"],
  },
  Azerbaijan: {
    name: "Azerbaijan",
    capital: "Baku",
    assist: "box",
    class: ["quiz-country"],
    latlng: [40.5, 47.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Bahrain: {
    name: "Bahrain",
    capital: "Manama",
    // assist: true,
    small: true,
    class: ["quiz-country"],
    latlng: [26, 50.55],
    styles: ["#4A9B61", "#5FD981"],
  },
  Bangladesh: {
    name: "Bangladesh",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [24, 90],
    styles: ["#4A9B61", "#5FD981"],
  },
  Bhutan: {
    name: "Bhutan",
    capital: "N/A",
    assist: "box",
    class: ["quiz-country"],
    latlng: [27.5, 90.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Brunei: {
    name: "Brunei",
    capital: "N/A",
    // assist: true,
    class: ["quiz-country"],
    small: true,
    latlng: [4.5, 114.66666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Cambodia: {
    name: "Cambodia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [13, 105],
    styles: ["#4A9B61", "#5FD981"],
  },
  China: {
    name: "China",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [35, 105],
    styles: ["#4A9B61", "#5FD981"],
  },
  // {
  //   name: "Cyprus", //////////////<<<<<<<<<<<<
  //   capital: "N/A"
  // },
  Georgia: {
    name: "Georgia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [42, 43.5],
    latlng: [20, 77],
    styles: ["#4A9B61", "#5FD981"],
  },
  India: {
    name: "India",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [20, 77],
    styles: ["#4A9B61", "#5FD981"],
  },
  Indonesia: {
    name: "Indonesia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-5, 120],
    styles: ["#4A9B61", "#5FD981"],
  },
  Iran: {
    name: "Iran",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [32, 53],
    styles: ["#4A9B61", "#5FD981"],
  },
  Iraq: {
    name: "Iraq",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [33, 44],
    styles: ["#4A9B61", "#5FD981"],
  },
  Israel: {
    name: "Israel",
    capital: "N/A",
    // assist: "box",
    small: true,
    class: ["quiz-country"],
    latlng: [31.47, 35.13],
    styles: ["#4A9B61", "#5FD981"],
  },
  Japan: {
    name: "Japan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [36, 138],
    styles: ["#4A9B61", "#5FD981"],
  },
  Jordan: {
    name: "Jordan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [31, 36],
    styles: ["#4A9B61", "#5FD981"],
  },
  Kazakhstan: {
    name: "Kazakhstan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [48, 68],
    styles: ["#4A9B61", "#5FD981"],
  },
  Kuwait: {
    name: "Kuwait",
    capital: "N/A",
    // assist: "box",
    small: true,
    class: ["quiz-country"],
    latlng: [29.5, 45.75],
    styles: ["#4A9B61", "#5FD981"],
  },
  Kyrgyzstan: {
    name: "Kyrgyzstan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [41, 75],
    styles: ["#4A9B61", "#5FD981"],
  },
  Laos: {
    name: "Laos",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [18, 105],
    styles: ["#4A9B61", "#5FD981"],
  },
  Lebanon: {
    name: "Lebanon",
    capital: "N/A",
    // assist: "box",
    small: true,
    class: ["quiz-country"],
    latlng: [33.83333333, 35.83333333],
    styles: ["#4A9B61", "#5FD981"],
  },
  Malaysia: {
    name: "Malaysia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [2.5, 112.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Maldives: {
    name: "Maldives",
    capital: "N/A",
    // assist: true,
    class: ["quiz-country"],
    small: true,
    latlng: [3.25, 73],
    styles: ["#4A9B61", "#5FD981"],
  },
  Mongolia: {
    name: "Mongolia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [46, 105],
    styles: ["#4A9B61", "#5FD981"],
  },
  Myanmar: {
    name: "Myanmar",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [22, 98],
    styles: ["#4A9B61", "#5FD981"],
  },
  Nepal: {
    name: "Nepal",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [28, 84],
    styles: ["#4A9B61", "#5FD981"],
  },
  "North-Korea": {
    name: "North Korea",
    id: "North-Korea",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [40, 127],
    styles: ["#4A9B61", "#5FD981"],
  },
  Oman: {
    name: "Oman",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [21, 57],
    styles: ["#4A9B61", "#5FD981"],
  },
  Pakistan: {
    name: "Pakistan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [30, 70],
    styles: ["#4A9B61", "#5FD981"],
  },
  Philippines: {
    name: "Philippines",
    capital: "N/A",
    // assist: "box",
    class: ["quiz-country"],
    latlng: [13, 122],
    styles: ["#4A9B61", "#5FD981"],
  },
  Qatar: {
    name: "Qatar",
    capital: "N/A",
    // assist: true,
    small: true,
    class: ["quiz-country"],
    latlng: [25.5, 51.25],
    styles: ["#4A9B61", "#5FD981"],
  },
  Russia: {
    name: "Russia",
    capital: "Moscow",
    class: ["quiz-country"],
    latlng: [60, 100],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Saudi-Arabia": {
    name: "Saudi Arabia",
    id: "Saudi-Arabia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [25, 45],
    styles: ["#4A9B61", "#5FD981"],
  },
  Singapore: {
    name: "Singapore",
    capital: "N/A",
    // assist: true,
    class: ["quiz-country"],
    small: true,
    latlng: [1.36666666, 103.8],
    styles: ["#4A9B61", "#5FD981"],
  },
  "South-Korea": {
    name: "South Korea",
    id: "South-Korea",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [37, 127.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Sri-Lanka": {
    name: "Sri Lanka",
    id: "Sri-Lanka",
    capital: "N/A",
    assist: "box",
    // small: true,
    class: ["quiz-country"],
    latlng: [7, 81],
    styles: ["#4A9B61", "#5FD981"],
  },
  // {
  //   name: "State of Palestine",
  //   id: "State-of-Palestine",
  //   capital: "N/A"
  // },
  Syria: {
    name: "Syria",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [35, 38],
    styles: ["#4A9B61", "#5FD981"],
  },
  Taiwan: {
    name: "Taiwan",
    capital: "N/A",
    assist: "box",
    class: ["quiz-country"],
    latlng: [23.5, 121],
    styles: ["#4A9B61", "#5FD981"],
  },
  Tajikistan: {
    name: "Tajikistan",
    capital: "N/A",
    // assist: "box",
    class: ["quiz-country"],
    latlng: [39, 71],
    styles: ["#4A9B61", "#5FD981"],
  },
  Thailand: {
    name: "Thailand",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [15, 100],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Timor-Leste": {
    name: "Timor Leste",
    id: "Timor-Leste",
    // assist: "ring",
    small: true,
    capital: "Dili",
    class: ["quiz-country"],
    latlng: [-8.83333333, 125.91666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Turkey: {
    name: "Turkey",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [39, 35],
    styles: ["#4A9B61", "#5FD981"],
  },
  Turkmenistan: {
    name: "Turkmenistan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [40, 60],
    styles: ["#4A9B61", "#5FD981"],
  },
  "United-Arab-Emirates": {
    name: "United Arab Emirates",
    id: "United-Arab-Emirates",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [24, 54],
    styles: ["#4A9B61", "#5FD981"],
  },
  Uzbekistan: {
    name: "Uzbekistan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [41, 64],
    styles: ["#4A9B61", "#5FD981"],
  },
  Vietnam: {
    name: "Vietnam",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [16.16666666, 107.83333333],
    styles: ["#4A9B61", "#5FD981"],
  },
  Yemen: {
    name: "Yemen",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [15, 48],
    styles: ["#4A9B61", "#5FD981"],
  },
};
