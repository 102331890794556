export const regions = {
  NorthEast: [
    "Connecticut",
    "Delaware",
    "Maine",
    "Maryland",
    "Massachusetts",
    "New-Hampshire",
    "New-Jersey",
    "New-York",
    "Pennsylvania",
    "Rhode-Island",
    "Vermont",
  ],
  South: [
    "Alabama",
    "Arkansas",
    "Florida",
    "Georgia",
    "Kentucky",
    "Louisiana",
    "Mississippi",
    "North-Carolina",
    "Oklahoma",
    "South-Carolina",
    "Tennessee",
    "Texas",
    "Virginia",
  ],
  MidWest: [
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Nebraska",
    "North-Dakota",
    "Ohio",
    "South-Dakota",
    "Wisconsin",
  ],
  West: [
    "Arizona",
    "California",
    "Colorado",
    "Idaho",
    "Montana",
    "Nevada",
    "New-Mexico",
    "Oregon",
    "Utah",
    "Wyoming",
    "Alaska",
    "Hawaii",
    "Washington",
  ],
};

export default {
  Alabama: {
    name: "Alabama",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [32.31, -86.9],
    styles: ["#4A9B61", "#5FD981"],
  },
  Alaska: {
    name: "Alaska",
    capital: "N/A",
    assist: "box",
    class: ["quiz-country"],
    latlng: [66.16, -153.36],
    styles: ["#4A9B61", "#5FD981"],
  },
  Arizona: {
    name: "Arizona",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [34.04, -111.09],
    styles: ["#4A9B61", "#5FD981"],
  },
  Arkansas: {
    name: "Arkansas",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [34.79, -92.19],
    styles: ["#4A9B61", "#5FD981"],
  },
  California: {
    name: "California",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [36.77, -119.41],
    styles: ["#4A9B61", "#5FD981"],
  },
  Colorado: {
    name: "Colorado",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [39.11, -105.35],
    styles: ["#4A9B61", "#5FD981"],
  },
  Connecticut: {
    name: "Connecticut",
    capital: "N/A",
    assist: "box",
    class: ["quiz-country"],
    latlng: [41.59, -72.69],
    styles: ["#4A9B61", "#5FD981"],
  },
  Delaware: {
    name: "Delaware",
    capital: "N/A",
    assist: "box",
    class: ["quiz-country"],
    latlng: [39.0, -75.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Florida: {
    name: "Florida",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [27.99, -81.76],
    styles: ["#4A9B61", "#5FD981"],
  },
  Georgia: {
    name: "Georgia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [33.24, -83.44],
    styles: ["#4A9B61", "#5FD981"],
  },
  Hawaii: {
    name: "Hawaii",
    capital: "N/A",
    assist: "box",
    class: ["quiz-country"],
    latlng: [19.74, -155.84],
    styles: ["#4A9B61", "#5FD981"],
  },
  Idaho: {
    name: "Idaho",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [44.06, -114.74],
    styles: ["#4A9B61", "#5FD981"],
  },
  Illinois: {
    name: "Illinois",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [40.0, -89.0],
    styles: ["#4A9B61", "#5FD981"],
  },
  Indiana: {
    name: "Indiana",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [40.27, -86.12],
    styles: ["#4A9B61", "#5FD981"],
  },
  Iowa: {
    name: "Iowa",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [42.03, -93.58],
    styles: ["#4A9B61", "#5FD981"],
  },
  Kansas: {
    name: "Kansas",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [38.5, -98.0],
    styles: ["#4A9B61", "#5FD981"],
  },
  Kentucky: {
    name: "Kentucky",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [37.83, -84.27],
    styles: ["#4A9B61", "#5FD981"],
  },
  Louisiana: {
    name: "Louisiana",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [30.39, -92.32],
    styles: ["#4A9B61", "#5FD981"],
  },
  Maine: {
    name: "Maine",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [45.36, -68.97],
    styles: ["#4A9B61", "#5FD981"],
  },
  Maryland: {
    name: "Maryland",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [39.04, -76.64],
    styles: ["#4A9B61", "#5FD981"],
  },
  Massachusetts: {
    name: "Massachusetts",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [42.4, -71.38],
    styles: ["#4A9B61", "#5FD981"],
  },
  Michigan: {
    name: "Michigan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [44.18, -84.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Minnesota: {
    name: "Minnesota",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [46.39, -94.63],
    styles: ["#4A9B61", "#5FD981"],
  },
  Mississippi: {
    name: "Mississippi",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [33.0, -90.0],
    styles: ["#4A9B61", "#5FD981"],
  },
  Missouri: {
    name: "Missouri",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [38.57, -92.6],
    styles: ["#4A9B61", "#5FD981"],
  },
  Montana: {
    name: "Montana",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [46.96, -109.53],
    styles: ["#4A9B61", "#5FD981"],
  },
  Nebraska: {
    name: "Nebraska",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [41.5, -100.0],
    styles: ["#4A9B61", "#5FD981"],
  },
  Nevada: {
    name: "Nevada",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [39.87, -117.22],
    styles: ["#4A9B61", "#5FD981"],
  },
  "New-Hampshire": {
    id: "New-Hampshire",
    name: "New Hampshire",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [44.0, -71.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  "New-Jersey": {
    id: "New-Jersey",
    name: "New Jersey",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [39.83, -74.87],
    styles: ["#4A9B61", "#5FD981"],
  },
  "New-Mexico": {
    id: "New-Mexico",
    name: "New Mexico",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [34.3, -106.01],
    styles: ["#4A9B61", "#5FD981"],
  },
  "New-York": {
    id: "New-York",
    name: "New York",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [43.0, -75.0],
    styles: ["#4A9B61", "#5FD981"],
  },
  "North-Carolina": {
    id: "North-Carolina",
    name: "North Carolina",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [35.78, -80.79],
    styles: ["#4A9B61", "#5FD981"],
  },
  "North-Dakota": {
    id: "North-Dakota",
    name: "North Dakota",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [47.65, -100.43],
    styles: ["#4A9B61", "#5FD981"],
  },
  Ohio: {
    name: "Ohio",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [40.36, -82.99],
    styles: ["#4A9B61", "#5FD981"],
  },
  Oklahoma: {
    name: "Oklahoma",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [36.08, -96.92],
    styles: ["#4A9B61", "#5FD981"],
  },
  Oregon: {
    name: "Oregon",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [44.0, -120.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Pennsylvania: {
    name: "Pennsylvania",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [41.2, -77.19],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Rhode-Island": {
    id: "Rhode-Island",
    name: "Rhode Island",
    assist: "box",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [41.74, -71.74],
    styles: ["#4A9B61", "#5FD981"],
  },
  "South-Carolina": {
    id: "South-Carolina",
    name: "South Carolina",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [33.83, -81.16],
    styles: ["#4A9B61", "#5FD981"],
  },
  "South-Dakota": {
    id: "South-Dakota",
    name: "South Dakota",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [44.5, -100.0],
    styles: ["#4A9B61", "#5FD981"],
  },
  Tennessee: {
    name: "Tennessee",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [35.86, -86.66],
    styles: ["#4A9B61", "#5FD981"],
  },
  Texas: {
    name: "Texas",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [31.0, -100.0],
    styles: ["#4A9B61", "#5FD981"],
  },
  Utah: {
    name: "Utah",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [39.41, -111.95],
    styles: ["#4A9B61", "#5FD981"],
  },
  Vermont: {
    name: "Vermont",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [44.0, -72.69],
    styles: ["#4A9B61", "#5FD981"],
  },
  Virginia: {
    name: "Virginia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [37.92, -78.02],
    styles: ["#4A9B61", "#5FD981"],
  },
  Washington: {
    name: "Washington",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [47.75, -120.74],
    styles: ["#4A9B61", "#5FD981"],
  },
  "West-Virginia": {
    id: "West-Virginia",
    name: "West Virginia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [39.0, -80.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Wisconsin: {
    name: "Wisconsin",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [44.5, -89.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Wyoming: {
    name: "Wyoming",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [43.07, -107.29],
    styles: ["#4A9B61", "#5FD981"],
  },
};
