import React from "react"
import { useHistory } from "react-router-dom"
import "./HomeBtns.css"

export default function HomeBtns() {
  const history = useHistory()

  return (
    <div className="home-mainBtns home-buttons">
      <p id="home-slogan">Geography made fun and easy!</p>
      <p className="home-slogan-subtext">
        Learn geography through Quizzes and multiple choice much more.
      </p>
      <div className="home-btns-panel">
        <button
          id="home-quizBtn"
          className="selectable"
          onClick={() => window.scrollTo(0, document.body.scrollHeight)}
          // onClick={() => history.push("/quiz/europe/countries")}
        >
          Explore
        </button>
      </div>
    </div>
  )
}
