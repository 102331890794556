export const regions = {
  "Northern Europe": [
    "United Kingdom",
    "Norway",
    "Denmark",
    "Estonia",
    "Iceland",
    "Ireland",
    "Latvia",
    "Lithuania",
    "Finland",
    "Sweden",
  ],
  "Central Europe": [
    "Andorra",
    "Austria",
    "Belgium",
    "France",
    "Germany",
    "Liechtenstein",
    "Luxembourg",
    "Netherlands",
    "Switzerland",
  ],
  "Eastern Europe": [
    "Azerbaijan",
    "Belarus",
    "Bulgaria",
    "Czechia",
    "Georgia",
    "Hungary",
    "Moldova",
    "Poland",
    "Romania",
    "Russia",
    "Slovakia",
    "Ukraine",
  ],
  "Southern Europe": [
    "Albania",
    "Bosnia and Herzegovina",
    "Croatia",
    "Cyprus",
    "Greece",
    "Italy",
    "Kosovo",
    "Monaco",
    "Malta",
    "Montenegro",
    "North Macedonia",
    "Portugal",
    "San Marino",
    "Serbia",
    "Spain",
    "Turkey",
    "Vatican-City",
  ],
};

export default {
  Albania: {
    name: "Albania",
    capital: "Tirana",
    class: ["quiz-country"],
    styles: ["#4A9B61", "#5FD981"],
    latlng: [41, 20],
  },
  Andorra: {
    name: "Andorra",
    capital: "Andorra la Vella",
    class: ["quiz-country"],
    small: {
      order: 5,
    },
    styles: ["#4A9B61", "#5FD981"],
    latlng: [42.5, 1.5],
  },
  Austria: {
    name: "Austria",
    capital: "Vienna",
    class: ["quiz-country"],
    styles: ["#4A9B61", "#5FD981"],
    latlng: [47.33333333, 13.33333333],
  },
  Azerbaijan: {
    name: "Azerbaijan",
    capital: "Baku",
    class: ["quiz-country"],
    latlng: [40.5, 47.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Belarus: {
    name: "Belarus",
    capital: "Minsk",
    class: ["quiz-country"],
    latlng: [53, 28],
    styles: ["#4A9B61", "#5FD981"],
  },
  Belgium: {
    name: "Belgium",
    capital: "Brussels",
    class: ["quiz-country"],
    latlng: [50.83333333, 4],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Bosnia-and-Herzegovina": {
    name: "Bosnia and Herzegovina",
    id: "Bosnia-and-Herzegovina",
    capital: "Sarajevo",
    class: ["quiz-country"],
    latlng: [44, 18],
    styles: ["#4A9B61", "#5FD981"],
  },
  Bulgaria: {
    name: "Bulgaria",
    capital: "Sofia",
    class: ["quiz-country"],
    latlng: [43, 25],
    styles: ["#4A9B61", "#5FD981"],
  },
  Croatia: {
    name: "Croatia",
    capital: "Zagreb",
    class: ["quiz-country"],
    latlng: [45.16666666, 15.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Cyprus: {
    name: "Cyprus",
    capital: "Nicosia",
    // assist: true,
    small: true,
    class: ["quiz-country"],
    latlng: [35, 33],
    styles: ["#4A9B61", "#5FD981"],
  },
  Czechia: {
    name: "Czechia",
    capital: "Prague",
    class: ["quiz-country"],
    latlng: [49.75, 15.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Denmark: {
    extraData: {
      population: "5,717,014",
      land: "42,933 km²",
      language: "Danish",
      animal: "Mute Swan",
    },
    name: "Denmark",
    capital: "Copenhagen",
    class: ["quiz-country"],
    latlng: [56, 10],
    styles: ["#4A9B61", "#5FD981"],
  },
  Estonia: {
    extraData: {
      population: "1,315,944",
      land: "45,226 km²",
      language: "Estonian",
      animal: "grey wolf",
    },
    name: "Estonia",
    capital: "Tallinn",
    class: ["quiz-country"],
    latlng: [59, 26],
    styles: ["#4A9B61", "#5FD981"],
  },
  Finland: {
    extraData: {
      population: "5,491,817",
      land: "338,440 km²",
      language: "Finnish, Swedish",
      animal: "brown bear",
    },
    name: "Finland",
    // toolTipPos: "left",
    capital: "Helsinki",
    class: ["quiz-country"],
    latlng: [64, 26],
    styles: ["#4A9B61", "#5FD981"],
  },
  France: {
    name: "France",
    capital: "Paris",
    class: ["quiz-country"],
    latlng: [46, 2],
    styles: ["#4A9B61", "#5FD981"],
  },
  Georgia: {
    name: "Georgia",
    capital: "Tbilisi",
    class: ["quiz-country"],
    latlng: [42, 43.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Germany: {
    name: "Germany",
    capital: "Berlin",
    class: ["quiz-country"],
    latlng: [51, 9],
    styles: ["#4A9B61", "#5FD981"],
  },
  Greece: {
    name: "Greece",
    capital: "Athens",
    class: ["quiz-country"],
    latlng: [39, 22],
    styles: ["#4A9B61", "#5FD981"],
  },
  Hungary: {
    name: "Hungary",
    capital: "Budapest",
    class: ["quiz-country"],
    latlng: [47, 20],
    styles: ["#4A9B61", "#5FD981"],
  },
  Iceland: {
    extraData: {
      population: "334,300",
      land: "103,000 km²",
      language: "Icelandic",
      animal: "Gyrfalcon",
    },
    name: "Iceland",
    capital: "Reykjavík",
    class: ["quiz-country"],
    latlng: [65, -18],
    styles: ["#4A9B61", "#5FD981"],
  },
  Ireland: {
    extraData: {
      population: "6,378,000",
      land: "84,421 km²",
      language: "Irish, English",
      animal: "Irish hare",
    },
    name: "Ireland",
    capital: "Dublin",
    class: ["quiz-country"],
    latlng: [53, -8],
    styles: ["#4A9B61", "#5FD981"],
  },
  Italy: {
    name: "Italy",
    capitals: "Rome",
    class: ["quiz-country"],
    latlng: [42.83333333, 12.83333333],
    styles: ["#4A9B61", "#5FD981"],
  },
  Kosovo: {
    name: "Kosovo",
    capital: "Prishtina",
    assist: true,
    class: ["quiz-country"],
    latlng: [42.666667, 21.166667],
    styles: ["#4A9B61", "#5FD981"],
  },
  Latvia: {
    extraData: {
      population: "1,961,600",
      land: "64,589 km²",
      language: "Latvian",
      animal: "Two-spotted ladybird",
    },
    name: "Latvia",
    capital: "Riga",
    class: ["quiz-country"],
    latlng: [57, 25],
    styles: ["#4A9B61", "#5FD981"],
  },
  Liechtenstein: {
    name: "Liechtenstein",
    capital: "Vaduz",
    class: ["quiz-country"],
    small: {
      order: 2,
    },
    latlng: [47.26666666, 9.53333333],
    styles: ["#4A9B61", "#5FD981"],
  },
  Lithuania: {
    extraData: {
      population: "2,872,294",
      land: "65,300 km²",
      language: "Lithuanian",
      animal: "white stork",
    },
    name: "Lithuania",
    capital: "Vilnius",
    class: ["quiz-country"],
    latlng: [56, 24],
    styles: ["#4A9B61", "#5FD981"],
  },
  Luxembourg: {
    name: "Luxembourg",
    capital: "Luxembourg",
    class: ["quiz-country"],
    small: {
      order: 1,
    },
    latlng: [49.75, 6.16666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Malta: {
    name: "Malta",
    capital: "Valletta",
    class: ["quiz-country"],
    small: {
      order: 7,
    },
    latlng: [35.83333333, 14.58333333],
    styles: ["#4A9B61", "#5FD981"],
  },
  Moldova: {
    name: "Moldova",
    capital: "Chisinau",
    class: ["quiz-country"],
    latlng: [47, 29],
    styles: ["#4A9B61", "#5FD981"],
  },
  Monaco: {
    name: "Monaco",
    capital: "Monte Carlo",
    class: ["quiz-country"],
    small: {
      order: 4,
    },
    latlng: [43.73333333, 7.4],
    styles: ["#4A9B61", "#5FD981"],
  },
  Montenegro: {
    name: "Montenegro",
    capital: "Podgorica",
    assist: true,
    class: ["quiz-country"],
    latlng: [42.5, 19.3],
    styles: ["#4A9B61", "#5FD981"],
  },
  Netherlands: {
    name: "Netherlands",
    capital: "Amsterdam",
    class: ["quiz-country"],
    latlng: [52.5, 5.75],
    styles: ["#4A9B61", "#5FD981"],
  },
  "North-Macedonia": {
    name: "North Macedonia",
    id: "North-Macedonia",
    capital: "Skopje",
    class: ["quiz-country"],
    latlng: [41.83333333, 22],
    styles: ["#4A9B61", "#5FD981"],
  },
  Norway: {
    extraData: {
      population: "5,223,256",
      land: "385,203 km²",
      language: "Norwegian",
      animal: "Moose",
    },
    name: "Norway",
    // toolTipPos: "left",
    capital: "Oslo",
    class: ["quiz-country"],
    latlng: [62, 10],
    styles: ["#4A9B61", "#5FD981"],
  },
  Poland: {
    name: "Poland",
    capital: "Warsaw",
    class: ["quiz-country"],
    latlng: [52, 20],
    styles: ["#4A9B61", "#5FD981"],
  },
  Portugal: {
    name: "Portugal",
    capital: "Lisbon",
    class: ["quiz-country"],
    latlng: [39.5, -8],
    styles: ["#4A9B61", "#5FD981"],
  },
  Romania: {
    name: "Romania",
    capital: "Bucharest",
    class: ["quiz-country"],
    latlng: [46, 25],
    styles: ["#4A9B61", "#5FD981"],
  },
  Russia: {
    name: "Russia",
    capital: "Moscow",
    class: ["quiz-country"],
    // toolTipPos: "left",
    // toolTipOffset: "{'left: 25}",
    latlng: [60, 100],
    styles: ["#4A9B61", "#5FD981"],
  },
  "San-Marino": {
    name: "San Marino",
    id: "San-Marino",
    capital: "San Marino",
    class: ["quiz-country"],
    small: {
      order: 3,
    },
    latlng: [43.76666666, 12.41666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Serbia: {
    name: "Serbia",
    capital: "Belgrade",
    class: ["quiz-country"],
    latlng: [44, 21],
    styles: ["#4A9B61", "#5FD981"],
  },
  Slovakia: {
    name: "Slovakia",
    capital: "Bratislava",
    class: ["quiz-country"],
    latlng: [48.66666666, 19.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Slovenia: {
    name: "Slovenia",
    capital: "Ljubljana",
    class: ["quiz-country"],
    latlng: [46.11666666, 14.81666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Spain: {
    name: "Spain",
    capital: "Madrid",
    class: ["quiz-country"],
    latlng: [40, -4],
    styles: ["#4A9B61", "#5FD981"],
  },
  Sweden: {
    extraData: {
      population: "9,894,888",
      land: "450,295 km²",
      language: "Swedish",
      animal: "Moose/Elk",
    },
    // toolTipPos: "left",
    name: "Sweden",
    capital: "Stockholm",
    class: ["quiz-country"],
    latlng: [62, 15],
    styles: ["#4A9B61", "#5FD981"],
  },
  Switzerland: {
    name: "Switzerland",
    capital: "Bern",
    class: ["quiz-country"],
    latlng: [47, 8],
    styles: ["#4A9B61", "#5FD981"],
  },
  Turkey: {
    name: "Turkey",
    capital: "Ankara",
    class: ["quiz-country"],
    latlng: [39, 35],
    styles: ["#4A9B61", "#5FD981"],
  },
  Ukraine: {
    name: "Ukraine",
    capital: "Kiev",
    class: ["quiz-country"],
    latlng: [49, 32],
    styles: ["#4A9B61", "#5FD981"],
  },
  "United-Kingdom": {
    extraData: {
      population: "65,110,000",
      land: "242,495 km²",
      language: "English",
      animal: "Lion",
    },
    name: "United Kingdom",
    id: "United-Kingdom",
    capital: "London",
    class: ["quiz-country"],
    latlng: [54, -2],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Vatican-City": {
    name: "Vatican City",
    id: "Vatican-City",
    capital: "Vatican City",
    class: ["quiz-country"],
    small: {
      order: 6,
    },
    latlng: [41.9, 12.45],
    styles: ["#4A9B61", "#5FD981"],
  },
};
