export const regions = {
  "northern-africa": [
    "Algeria",
    "Egypt",
    "Libya",
    "Morocco",
    "Sudan",
    "Tunisia",
    "Western-Sahara",
  ],
  "western-africa": [
    "Benin",
    "Cabo-Verde",
    "Cote d'Ivoire",
    "The Gambia",
    "Guinea Bissau",
    "Guinea",
    "Ghana",
    "Liberia",
    "Mali",
    "Mauritania",
    "Senegal",
    "Sierra Leone",
    "Togo",
    "Nigeria",
    "Burkina Faso",
    "Niger",
  ],
  "central-africa": [
    "Angola",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "DR Congo",
    "Equatorial Guinea",
    "Gabon",
    "Congo",
    "Sao Tome",
  ],
  "eastern-africa": [
    "Burundi",
    "Comoros",
    "Eritrea",
    "Ethiopia",
    "Kenya",
    "Madagascar",
    "Mozambique",
    "Rwanda",
    "Seychelles",
    "Somalia",
    "South Sudan",
    "Tanzania",
    "Uganda",
    "Zambia",
    "Zimbabwe",
    "Djibouti",
    "Mauritius",
    "Malawi",
  ],
  "southern-africa": [
    "Botswana",
    "Eswatini",
    "Lesotho",
    "Namibia",
    "South Africa",
  ],
};

export default {
  Algeria: {
    name: "Algeria",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [28, 3],
    styles: ["#4A9B61", "#5FD981"],
  },
  Angola: {
    name: "Angola",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-12.5, 18.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Benin: {
    name: "Benin",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [9.5, 2.25],
    styles: ["#4A9B61", "#5FD981"],
  },
  Botswana: {
    name: "Botswana",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-22, 24],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Burkina-Faso": {
    name: "Burkina Faso",
    id: "Burkina-Faso",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [13, -2],
    styles: ["#4A9B61", "#5FD981"],
  },
  Burundi: {
    name: "Burundi",
    capital: "N/A",
    // assist: "box",
    small: true,
    class: ["quiz-country"],
    latlng: [-3.5, 30],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Cabo-Verde": {
    name: "Cabo Verde",
    id: "Cabo-Verde",
    assist: true,
    // small: {
    //   order: 1,
    // },
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [16, -24],
    styles: ["#4A9B61", "#5FD981"],
  },
  Cameroon: {
    name: "Cameroon",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [6, 12],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Central-African-Republic": {
    name: "Central African Republic",
    id: "Central-African-Republic",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [7, 21],
    styles: ["#4A9B61", "#5FD981"],
  },
  Chad: {
    name: "Chad",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [15, 19],
    styles: ["#4A9B61", "#5FD981"],
  },
  Comoros: {
    name: "Comoros",
    capital: "N/A",
    // assist: true,
    small: {
      order: 1,
    },
    class: ["quiz-country"],
    latlng: [-12.16666666, 44.25],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Cote-d-Ivoire": {
    name: "Côte d'Ivoire",
    id: "Cote-d-Ivoire",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [8, -5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Djibouti: {
    name: "Djibouti",
    capital: "N/A",
    // assist: "box",
    small: true,
    class: ["quiz-country"],
    latlng: [11.5, 43],
    styles: ["#4A9B61", "#5FD981"],
  },
  "DR-Congo": {
    name: "Democratic Republic of the Congo",
    id: "DR-Congo",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [0, 25],
    styles: ["#4A9B61", "#5FD981"],
  },
  Egypt: {
    name: "Egypt",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [27, 30],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Equatorial-Guinea": {
    name: "Equatorial Guinea",
    id: "Equatorial-Guinea",
    // assist: "box",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [2, 10],
    styles: ["#4A9B61", "#5FD981"],
  },
  Eritrea: {
    name: "Eritrea",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [15, 39],
    styles: ["#4A9B61", "#5FD981"],
  },
  Eswatini: {
    name: "Eswatini",
    capital: "N/A",
    // assist: "box",
    small: true,
    class: ["quiz-country"],
    latlng: [-26.5, 31.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Ethiopia: {
    name: "Ethiopia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [8, 38],
    styles: ["#4A9B61", "#5FD981"],
  },
  Gabon: {
    name: "Gabon",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-1, 11.75],
    styles: ["#4A9B61", "#5FD981"],
  },
  "The-Gambia": {
    id: "The-Gambia",
    name: "The Gambia",
    assist: "box",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [13.46666666, -16.56666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Ghana: {
    name: "Ghana",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [8, -2],
    styles: ["#4A9B61", "#5FD981"],
  },
  Guinea: {
    name: "Guinea",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [11, -10],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Guinea-Bissau": {
    name: "Guinea Bissau",
    id: "Guinea-Bissau",
    assist: "box",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [12, -15],
    styles: ["#4A9B61", "#5FD981"],
  },
  Kenya: {
    name: "Kenya",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [1, 38],
    styles: ["#4A9B61", "#5FD981"],
  },
  Lesotho: {
    name: "Lesotho",
    capital: "N/A",
    // assist: "box",
    small: true,
    class: ["quiz-country"],
    latlng: [-29.5, 28.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Liberia: {
    name: "Liberia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [6.5, -9.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Libya: {
    name: "Libya",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [25, 17],
    styles: ["#4A9B61", "#5FD981"],
  },
  Madagascar: {
    name: "Madagascar",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-20, 47],
    styles: ["#4A9B61", "#5FD981"],
  },
  Malawi: {
    name: "Malawi",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-13.5, 34],
    styles: ["#4A9B61", "#5FD981"],
  },
  Mali: {
    name: "Mali",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [17, -4],
    styles: ["#4A9B61", "#5FD981"],
  },
  Mauritania: {
    name: "Mauritania",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [20, -12],
    styles: ["#4A9B61", "#5FD981"],
  },
  Mauritius: {
    name: "Mauritius",
    capital: "N/A",
    // assist: true,
    small: {
      order: 1,
    },
    class: ["quiz-country"],
    latlng: [-20.28333333, 57.55],
    styles: ["#4A9B61", "#5FD981"],
  },
  Morocco: {
    name: "Morocco",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [32, -5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Mozambique: {
    name: "Mozambique",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-18.25, 35],
    styles: ["#4A9B61", "#5FD981"],
  },
  Namibia: {
    name: "Namibia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-22, 17],
    styles: ["#4A9B61", "#5FD981"],
  },
  Niger: {
    name: "Niger",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [16, 8],
    styles: ["#4A9B61", "#5FD981"],
  },
  Nigeria: {
    name: "Nigeria",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [10, 8],
    styles: ["#4A9B61", "#5FD981"],
  },
  Congo: {
    name: "Republic of the Congo",
    id: "Congo",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-1, 15],
    styles: ["#4A9B61", "#5FD981"],
  },
  Rwanda: {
    name: "Rwanda",
    capital: "N/A",
    // assist: "box",
    small: true,
    class: ["quiz-country"],
    latlng: [-2, 30],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Sao-Tome": {
    name: "Sao Tome and Principe",
    id: "Sao-Tome",
    capital: "N/A",
    // assist: true,
    small: {
      order: 1,
    },
    class: ["quiz-country"],
    latlng: [1, 7],
    styles: ["#4A9B61", "#5FD981"],
  },
  Senegal: {
    name: "Senegal",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [14, -14],
    styles: ["#4A9B61", "#5FD981"],
  },
  Seychelles: {
    name: "Seychelles",
    capital: "N/A",
    assist: true,
    // small: {
    //   order: 1,
    // },
    class: ["quiz-country"],
    latlng: [-4.58333333, 55.66666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Sierra-Leone": {
    name: "Sierra Leone",
    id: "Sierra-Leone",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [8.5, -11.5],
    styles: ["#4A9B61", "#5FD981"],
  },
  Somalia: {
    name: "Somalia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [10, 49],
    styles: ["#4A9B61", "#5FD981"],
  },
  "South-Africa": {
    name: "South Africa",
    id: "South-Africa",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-29, 24],
    styles: ["#4A9B61", "#5FD981"],
  },
  "South-Sudan": {
    name: "South Sudan",
    id: "South-Sudan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [7, 30],
    styles: ["#4A9B61", "#5FD981"],
  },
  Sudan: {
    name: "Sudan",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [15, 30],
    styles: ["#4A9B61", "#5FD981"],
  },
  Tanzania: {
    name: "Tanzania",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-6, 35],
    styles: ["#4A9B61", "#5FD981"],
  },
  Togo: {
    name: "Togo",
    capital: "N/A",
    assist: "box",
    class: ["quiz-country"],
    latlng: [8, 1.16666666],
    styles: ["#4A9B61", "#5FD981"],
  },
  Tunisia: {
    name: "Tunisia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [34, 9],
    styles: ["#4A9B61", "#5FD981"],
  },
  Uganda: {
    name: "Uganda",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [1, 32],
    styles: ["#4A9B61", "#5FD981"],
  },
  "Western-Sahara": {
    name: "Western Sahara (Disputed Territory)",
    id: "Western-Sahara",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [24.5, -13],
    styles: ["#4A9B61", "#5FD981"],
  },
  Zambia: {
    name: "Zambia",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-15, 30],
    styles: ["#4A9B61", "#5FD981"],
  },
  Zimbabwe: {
    name: "Zimbabwe",
    capital: "N/A",
    class: ["quiz-country"],
    latlng: [-20, 30],
    styles: ["#4A9B61", "#5FD981"],
  },
};
