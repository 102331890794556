import React, { useEffect } from "react"
import ShowMeButton from "../../Utils/Button/ShowMeBtn"
import SkipButton from "../../Utils/Button/SkipBtn"
import { ReactComponent as Reveal } from "../../../assets/images/reveal.svg"
import { ReactComponent as BtnSvg } from "../../../assets/images/game/skipBtn.svg"
import { ReactComponent as Mute } from "../../../assets/images/game/mute.svg"
import { ReactComponent as Muted } from "../../../assets/images/game/muted.svg"
export default function QuizButtons({
  handleSkip,
  handleShow,
  setMuted,
  styles,
  isMuted = false,
}) {
  const handleMute = () => {
    const newMuteState = !isMuted
    setMuted(newMuteState)
    localStorage.setItem("mute", newMuteState == true ? "true" : "false")
    // localstorage
  }

  // useEffect(() => {
  //   const muteFromStorage = localStorage.getItem("mute")
  //   setMuted(muteFromStorage == "yes" ? true : false)
  //   // setMuted(muteFromStorage)
  // }, [])

  return (
    <>
    <div className={styles.reveal}>
      <Reveal onClick={handleShow}></Reveal>
    </div>

      <div className={styles.skip} onClick={handleSkip}>
        <BtnSvg />
      </div>
      <div
        className={styles.muteIcon}
        data-isMuted={isMuted}
        onClick={handleMute}
      >
        {!isMuted ? <Mute /> : <Muted />}
      </div>
      {/* <ShowMeButton handleClick={handleShow} /> */}
      {/* <SkipButton handleClick={handleSkip} /> */}
    </>
  )
}
