import React from "react";
import style from "./list.module.scss";
import { useHistory } from "react-router-dom";

export default function QuizList() {
  const history = useHistory();

  const listData = [
    {
      place: "Europe",
      title: "Countries",
      img: require("../../assets/images/card-links/europe.png"),
      link: "europe/countries",
    },
    {
      place: "USA",
      title: "States",
      img: require("../../assets/images/card-links/united-states.png"),
      link: "north-america/states",
    },
    {
      place: "Asia",
      title: "Countries",
      img: require("../../assets/images/card-links/asia.png"),
      link: "asia/countries",
    },
    {
      place: "North America",
      title: "Countries",
      img: require("../../assets/images/card-links/na.png"),
      link: "north-america/countries",
    },
    {
      place: "Africa",
      title: "Countries",
      img: require("../../assets/images/card-links/africa.png"),
      link: "africa/countries",
    },
    {
      place: "Oceania",
      title: "Countries",
      img: require("../../assets/images/card-links/oceania.png"),
      link: "oceania/countries",
    },
    {
      place: "South America",
      title: "Countries",
      img: require("../../assets/images/card-links/sa.png"),
      link: "south-america/countries",
    },
    // {
    //     place: "World",
    //     title: "Continents",
    //     img: require("../../assets/images/card-links/europe.png"),
    //     isNew: true
    // },
  ];

  return (
    <div className={style.container}>
      <div className={style["list-top"]}>
        <div className={style["top-title"]}>Choose a quiz</div>
        {/* <div className={style['top-sections']}>
                    <div className={style['top-section-block']}>
                        All
                    </div>
                    <div className={style['top-section-block']}>
                        Countries
                    </div>
                    <div className={style['top-section-block']}>
                        Capitals
                    </div>
                    <div className={style['top-section-block']}>
                        States
                    </div>
                </div> */}
      </div>
      <div className={style["list-contents"]}>
        {listData.map((item, idx) => {
          return (
            <div
              key={idx}
              className={style["list-card"]}
              onClick={() => {
                history.push(`/quiz/${item.link}`);
              }}
            >
              <img src={item.img} />
              <p>
                <strong>{item.place}</strong>
              </p>
              <p>{item.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
